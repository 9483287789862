export function isExternalURL(url) {
    let urlObj;

    // Use a try-catch, because not all values passed to this function
    // can be computed to a URL object. Which is OK, but they shouldn't
    // be marked as 'external' url.
    try {
        urlObj = new URL(url);
    } catch {
        return false;
    }

    if (process.server) {
        return urlObj.origin !== process.env.APP_URL;
    } else {
        return urlObj.origin !== location.origin;
    }
}
