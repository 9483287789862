import { ref, computed, watch, onUnmounted } from 'vue';
import { useUiStore } from '~/store/ui';

export const useCollapse = () => {
    const uiStore = useUiStore();
    const { scrollPosition: scrollTop } = storeToRefs(uiStore);

    const lateCollapse = ref(true);

    const animationPlaying = computed(() => {
        return scrollTop.value < document.body.clientHeight - 1500;
    });

    onUnmounted(() => {
        uiStore.toggleLayoutCollapse(false);
    });

    watch(scrollTop, () => {
        // If the user has reduced motion enabled, we don't want to collapse the layout on late collapse pages
        if (uiStore.isMotionReduced && lateCollapse.value) {
            return;
        }

        let collapseAt = 0;

        if (lateCollapse.value) {
            collapseAt = 300;
        }

        if (scrollTop.value > collapseAt && animationPlaying.value) {
            uiStore.toggleLayoutCollapse(true);
        } else {
            uiStore.toggleLayoutCollapse(false);
        }
    });

    return {
        animationPlaying,
        lateCollapse,
        scrollTop,
        uiStore
    };
};
