<template>
    <ul class="discover-stream">
        <li
            v-for="(card, index) in cards"
            :key="index"
            class="discover-stream__item"
        >
            <template v-if="card.type === 'globalVideo'">
                <SfDiscoverCard
                    card-type="video"
                    :image="getImage(card.entry)"
                    :orientation="card.entry.orientation"
                    :title="card.entry.title"
                    :label="card.entry.label"
                    :label-url="card.entry.externalUrl"
                    :year="$d(parseISO(card.entry.postDate), 'year')"
                    :vimeo-url="card.entry.vimeoUrl"
                    :accessible-vimeo-url="card.entry.accessibleVimeoUrl"
                />
            </template>

            <template v-else-if="card.type === 'globalQuote'">
                <SfDiscoverCard
                    card-type="quote"
                    element="a"
                    :title="card.entry.title"
                    :to="card.entry.externalUrl"
                    :label="card.entry.label"
                    :label-url="card.entry.externalUrl"
                    :year="$d(parseISO(card.entry.postDate), 'year')"
                />
            </template>

            <template v-else-if="card.type === 'globalDossierCard'">
                <SfThemesCard
                    class="sf-themes-card--stack"
                    :title="card.entry.title"
                    :links="card.entry.links"
                    :image="card.entry.featuredImage[0]"
                    :description="card.entry.shortDescription"
                />
            </template>

            <template v-else-if="card.type === 'entry'">
                <SfDiscoverCard
                    :to="urlToPath(card.entry.url)"
                    :title="card.entry.title"
                    :image="getImage(card.entry)"
                    :label="$t(`entry-type.singular.${card.entry.entryType}`)"
                    :label-url="getLabelUrl(card.entry.entryType)"
                    :year="$d(parseISO(card.entry.postDate), 'year')"
                    :date="card.entry.fromDate"
                />
            </template>
        </li>
    </ul>
</template>

<script>
import { parseISO } from 'date-fns';

import SfDiscoverCard from '~/patterns/molecules/discover-card/discover-card.vue';
import SfThemesCard from '~/patterns/molecules/themes-card/themes-card.vue';

export default {
    components: {
        SfDiscoverCard,
        SfThemesCard
    },

    props: {
        entries: {
            type: Array,
            default: null
        },

        globalEntries: {
            type: Array,
            default: null
        },

        order: {
            type: String,
            default: null
        }
    },

    computed: {
        cards() {
            if (!this.entries || this.entries.length === 0) {
                return [];
            }

            const cards = this.entries.map((entry) => {
                return {
                    type: entry.entryType === 'globalVideo' ? 'globalVideo' : 'entry',
                    entry
                };
            });

            // Mix global cards into the regular ones
            this.globalCards.forEach((card, index) => {
                let position = 2; // First item

                if (this.order === 'startWithGlobal') {
                    position = 0;
                }

                if (index > 0) {
                    // Position a global item after every 2 regular entries
                    position = index * 3 + position;
                }

                if (position <= cards.length) {
                    cards.splice(position, 0, card);
                }
            });

            return cards;
        },

        globalCards() {
            if (!this.globalEntries || this.globalEntries.length === 0) {
                return [];
            }

            return this.globalEntries.map((entry) => {
                return {
                    type: entry.entryType,
                    entry
                };
            });
        }
    },

    methods: {
        parseISO,

        urlToPath,

        getLabelUrl(entryType) {
            switch (entryType) {
            case 'project':
                return this.localePath('projects');
            case 'news':
                return this.localePath('news');
            case 'article':
                return this.localePath('articles');
            case 'event':
                return this.localePath('events');
            case 'dossier':
                return this.localePath('dossiers');
            }
        },

        getImage(entry) {
            if (!entry || !entry.featuredImage || !entry.featuredImage.length > 0) {
                return;
            }

            return entry.featuredImage[0];
        }
    }
};
</script>

<style lang="less" src="./discover-stream.less"></style>
